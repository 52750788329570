import React from 'react'
import '../style/widecard.css';

function Widecard(props) {
    return (
        <div className="widecard">
            <div><img src={props.image} alt={props.alt} className="educationpic"></img></div>
            <div>
                <h3>{props.title}</h3>
                <h4 className="secondtext">{props.where}</h4>
                <h4 className="secondtext">{props.school}</h4>
                <h4 className="secondtext">{props.from} - {props.to}</h4>
            </div>
        </div>
    )
}
    
export default Widecard
    