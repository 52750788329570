import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FaAngleDoubleRight } from 'react-icons/fa'
import '../style/experience.css';
import jobs from '../data/experience.json';
import govtech_logo from "../img/govtech_logo.jpeg";
import imda_logo from "../img/imda_logo.png";
import dsta_logo from "../img/dsta_logo.png";
import finisar_logo from "../img/finisar_logo.jpg";
import dso_logo from "../img/dso_logo.jpg";

function Experience() {
    const [tabIndex, setTabIndex] = useState(0);
    const { company, dates, duties, title, synopsis } = jobs[tabIndex]
    
    return (
        <div className="condiv">
            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                <TabList>  
                    <Tab>GovTech Singapore</Tab>
                    <Tab>IMDA Singapore (Industry Development)</Tab>
                    <Tab>IMDA Singapore (Technology Planning)</Tab>
                    <Tab>DSTA</Tab>
                    <Tab>Finisar Corporation</Tab>
                    <Tab>DSO National Laboratories</Tab>
                </TabList>
                
                <TabPanel>    
                    <div className="panel-content">
                        <div><img src={govtech_logo} alt="govtech_logo.jpg" className="company_logo"></img></div>
                        <div>
                            <h2>{title}</h2>
                            <h3 style={{ color: "gray" }}>{dates}</h3>
                            <p>{synopsis}</p>
                            <br></br>
                            {duties.map((duty, index) => {
                                return (
                                    <div key={index} className="job-desc">
                                        <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                                        <p>{duty}</p>
                                    </div>   
                                ) 
                            })}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                <div className="panel-content">
                        <div><img src={imda_logo} alt="imda_logo.jpg" className="company_logo"></img></div>
                        <div>
                            <h2>{title}</h2>
                            <h3 style={{ color: "gray" }}>{dates}</h3>
                            <p>{synopsis}</p>
                            <br></br>
                            {duties.map((duty, index) => {
                                return (
                                    <div key={index} className="job-desc">
                                        <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                                        <p>{duty}</p>
                                    </div>   
                                ) 
                            })}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="panel-content">
                        <div><img src={imda_logo} alt="imda_logo.jpg" className="company_logo"></img></div>
                        <div>
                            <h2>{title}</h2>
                            <h3 style={{ color: "gray" }}>{dates}</h3>
                            <p>{synopsis}</p>
                            <br></br>
                            {duties.map((duty, index) => {
                                return (
                                    <div key={index} className="job-desc">
                                        <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                                        <p>{duty}</p>
                                    </div>   
                                ) 
                            })}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="panel-content">
                        <div><img src={dsta_logo} alt="dsta_logo.jpg" className="company_logo"></img></div>
                        <div>
                            <h2>{title}</h2>
                            <h3 style={{ color: "gray" }}>{dates}</h3>
                            <p>{synopsis}</p>
                            <br></br>
                            {duties.map((duty, index) => {
                                return (
                                    <div key={index} className="job-desc">
                                        <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                                        <p>{duty}</p>
                                    </div>   
                                ) 
                            })}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="panel-content">
                        <div><img src={finisar_logo} alt="finisar_logo.jpg" className="company_logo"></img></div>
                        <div>
                            <h2>{title}</h2>
                            <h3 style={{ color: "gray" }}>{dates}</h3>
                            <p>{synopsis}</p>
                            <br></br>
                            {duties.map((duty, index) => {
                                return (
                                    <div key={index} className="job-desc">
                                        <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                                        <p>{duty}</p>
                                    </div>   
                                ) 
                            })}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="panel-content">
                        <div><img src={dso_logo} alt="dso_logo.jpg" className="company_logo"></img></div>
                        <div>
                            <h2>{title}</h2>
                            <h3 style={{ color: "gray" }}>{dates}</h3>
                            <p>{synopsis}</p>
                            <br></br>
                            {duties.map((duty, index) => {
                                return (
                                    <div key={index} className="job-desc">
                                        <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                                        <p>{duty}</p>
                                    </div>   
                                ) 
                            })}
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );
}

export default Experience;