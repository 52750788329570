import React from 'react';
import '../style/contact.css';
import { FaLinkedin, FaGithub } from "react-icons/fa"

function Contact (props) {
    return (
        <div className="condiv">
            <div className="contactme">
                <h1 style={{fontSize: "60px", padding:"0 0 50px 0"}}>Contact Me</h1>
                <h2>I'm always keen to discuss new ideas and collaborations</h2>
                <div className="mailto">
                    <a className="mailto_button" href="mailto:junkal2002@gmail.com">Say Hi</a>
                </div>
                <div className="contactme contactme_social">
                    <a href="https://github.com/junkal" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
                    <a href="https://www.linkedin.com/in/christophersiajiunkae/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>    
                </div>
            </div>
        </div>    
    )
}
    
export default Contact;
    