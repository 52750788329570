import React from 'react';
import ReactTypingEffect from 'react-typing-effect';
import profilepic from '../img/christopher_2.jpg';
import { FaLinkedin, FaGithub } from "react-icons/fa"
import '../style/home.css';

function Home(props) {
    return (
        <div className="condiv home">
            <img src={profilepic} alt="ProfilePic" className="profilepic"></img>
            <ReactTypingEffect
                className="typingeffect"
                text={["Hi! I'm Christopher",
                    "I'm an AI Engineering Manager",
                    "I build AI Products"]}
                speed={100}
                eraseDelay={700} />
            <div className="home_social">
                <a href="https://github.com/junkal" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
                <a href="https://www.linkedin.com/in/christophersiajiunkae/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
            </div>
        </div>
    )   
}
        
export default Home;