import React from 'react';
import NtuLogo from '../img/ntu_logo.png'
import TemasekLogo from '../img/temasek_logo.jpg'
import DunmanHighLogo from '../img/dunmanhigh_logo.png'
import Widecard from '../components/Widecard';

function Education(props) {
    return (
        <div className="condiv">
            <Widecard
                image={NtuLogo}
                alt = "ntu_logo"
                title="Master of Business Administration (Specialisation in Strategy and Marketing)"
                where="Nanyang Technological University Singapore"
                school="Nanyang Business School"
                from="2008"
                to="2010" />
            <Widecard
                image={NtuLogo}
                alt = "ntu_logo"
                title="Bachelor of Engineering (Computer Engineering)"
                where="Nanyang Technological University Singapore"
                school="School of Computing"
                from="2001"
                to="2005" />
            <Widecard
                image={TemasekLogo}
                alt = "temasek_logo"
                title="GCE 'A' Level"
                where="Temasek Junior College Singapore"
                from="1997"
                to="1998" />
            <Widecard
                image={DunmanHighLogo}
                alt = "dunmanhigh_logo"
                title="GCE 'O' Level"
                where="Dunman High School Singapore"
                from="1993"
                to="1996" />            
        </div>
    )
}

export default Education
    