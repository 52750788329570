import React, { useEffect, useState } from "react";
import {BrowserRouter as Router,Route,} from "react-router-dom";
import NavigationBar from "./components/Navbar";
import Home from './contents/Home';
import About from "./contents/About";
import Experience from "./contents/Experience";
import Education from "./contents/Education";
import Portfolio from "./contents/Portfolio";
import Contact from "./contents/Contact";
import './style/app.css';

function App() {
  const [isMobile, setMobile] = useState(window.innerWidth < 426);

  const updateMedia = () => {
    setMobile(window.innerWidth < 426);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <Router>
      <div className="App">
        {!isMobile && <NavigationBar />}
        <Route exact path="/"><Home /></Route>        
        <Route path="/about"><About /></Route>
        <Route path="/experience"><Experience /></Route>
        <Route path="/portfolio"><Portfolio /></Route>
        <Route path="/education"><Education /></Route>
        <Route path="/contact"><Contact /></Route>
        {isMobile && <NavigationBar />}
      </div>
    </Router>
  );  
}

export default App;
