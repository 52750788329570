import React from 'react'
import itr_2012 from "../img/itr2012.png";
import vas_2020 from "../img/vas.png";
import quiz_app_2021 from "../img/quiz_app.png";
import credit_card_fraud_2017 from "../img/credit_card_fraud.png";
import covid19_app_2021 from "../img/covid-19_tracker.png";
import dcgan_faces from "../img/dcgan_faces.jpg";
import object_detection from "../img/object_detection.jpg";
import va_tech_challenge_2015 from "../img/va_tech_challenge.png";
import "../style/portfolio.css";
import { FaGithub } from 'react-icons/fa';
import { Grid, Card, CardContent } from '@mui/material';


function Portfolio() {
    return (
        <div className="portfolio_container">
            <Grid container spacing={2} direction="row" alignItems="flex-start" justifyContent="center">
                <Grid item md={3}>
                    <Card style={{ margin: "0px", padding:"0px"}}>
                        <CardContent>
                            <div className="column">
                                <img
                                    src={covid19_app_2021}
                                    alt="react covid-19 app"
                                    className="portfolio_img">
                                </img>
                                <h2>COVID-19 Tracker</h2>
                                <p>
                                    <a href="https://covid19-tracker.jiunkae.com/"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        COVID-19 dashboard 
                                    </a>
                                    &nbsp;&nbsp;using React.js.
                                    Track Singapore's numbers and compare them against rest of the world. Check out the code here&nbsp;
                                    <a href="https://github.com/junkal/web-app-developments/tree/main/%5BReact.js%5D%20Covid-19%20Tracker"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <FaGithub />
                                    </a>                    
                                </p>
                            </div>
                        </CardContent>
                    </Card>
                </Grid> 
                <Grid item md={3}>
                    <Card>
                        <CardContent>
                            <div className="column">
                                <img
                                    src={quiz_app_2021}
                                    alt="react quiz app"
                                    className="portfolio_img">
                                </img>
                                <h2>Quiz App</h2>
                                <p>
                                    <a href="https://quiz.jiunkae.com/"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        Quiz App
                                    </a>
                                    &nbsp;&nbsp;developed using React.JS, together with a bunch of React libraries.
                                    Did it for fun and to set fun quiz questions to friends. Check out the code &nbsp;
                                    <a href="https://github.com/junkal/web-app-developments/tree/main/%5BReact.js%5D%20Quiz-App"                                        
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <FaGithub />
                                    </a>
                                </p> 
                            </div>
                        </CardContent>
                    </Card>
            </Grid>
            <Grid item md={3}>
                    <Card>
                        <CardContent>
                            <div class="column">
                                <img
                                    src={dcgan_faces}
                                    alt="DCGan faces"
                                    className="portfolio_img">
                                </img>
                                <h2>DCGAN on Celebrity Faces</h2>
                                <p>Trained a DCGAN model to faces using celebrity faces dataset.
                                    Includes calculation of Frechet Inception Distance
                                    Check out the code &nbsp;
                                    <a href="https://github.com/junkal/GANs/tree/main/DCGAN%20on%20Celebfaces"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <FaGithub />
                                    </a>
                                </p>
                            </div>                                
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={3}>
                    <Card>
                        <CardContent>
                            <div class="column">
                                <img
                                    src={vas_2020}
                                    alt="video analytics system"
                                    className="portfolio_img">
                                </img>
                                <h2>Video Analytics System 2020</h2>
                                <p>Lead the team to develop and release VAS with VAaaS and model training capabilities.
                                    Talked about it during &nbsp;
                                    <a href="https://www.youtube.com/watch?v=tp_6jD8NKkA"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        Stack 2020 Conference
                                    </a>
                                </p>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={3}>
                    <Card>
                        <CardContent>
                        <div class="column">
                                <img
                                    src={object_detection}
                                    alt="Object Detection"
                                    className="portfolio_img">
                                </img>
                                <h2>Object Detection AI Models</h2>
                                <p>Trained a series of object detection model using YoloV3 and YoloV5.
                                </p>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={3}>
                    <Card>
                        <CardContent>
                        <div class="column">
                                <img
                                    src={credit_card_fraud_2017}
                                    alt="Credit Card Fraud"
                                    className="portfolio_img">
                                </img>
                                <h2>Credit Card Fraud Analysis</h2>
                                <p>Trained a Logistic Regression model to predict credit card fraud using data from Kaggle.
                                    Check out the code &nbsp;
                                    <a href="https://github.com/junkal/Credit-Card-Default-Analysis-Python"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <FaGithub />
                                    </a>
                                </p>
                            </div>                            
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={3}>
                    <Card>
                        <CardContent>
                            <div class="column">                                
                                <img
                                    src={va_tech_challenge_2015}
                                    alt="Video Analytics Tech Challenge 2015"
                                    className="portfolio_img">    
                                </img>
                                <h2>Video Analytics Tech Challenge 2015</h2>
                                <p>Conceptualised and implemented the inaugural<span> </span>
                                    <a href="https://www.businesstimes.com.sg/technology/minister-reiterates-singapores-smart-nation-goals-at-tech-showcase"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        Smart Nation Tech Challenge
                                    </a>
                                </p>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={3}>
                    <Card>
                        <CardContent>
                        <div class="column">
                                <img
                                src={itr_2012}
                                alt="Infocomm Technology Roadmap 2012"
                                className="portfolio_img">
                            </img>
                                <h2>Infocomm Technology Roadmap 2012</h2>
                                <p>Researched and published the report on <span></span>
                                    <a href="https://drive.google.com/file/d/0B7K28pIPAQZEUHFwc0dvekZnc2M/view?usp=sharing&resourcekey=0-qirw64O6cra3MjMZv8Zafg"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        Big Data        
                                    </a>
                                    <span></span> during <span></span>
                                    <a href="https://www.imda.gov.sg/news-and-events/Media-Room/archived/ida/Media-Releases/2012/infocomm-technology-roadmap-reviews-nine-technology-themes-that-impact-enterprises-and-organisations"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        ITR Synposium 2012
                                    </a>
                                </p>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
        // <div className="portfolio_container">
        //     {/* <div class="column">
        //         <img
        //             src={va_metadata_std_2018}
        //             alt="VA metadata standards"
        //             className="portfolio_img"
        //             style={{border: "1px solid grey"}}>
        //         </img>
        //         <h2 style={{fontSize:"18px"}}>Video Analytics Metadata Standards 2018</h2>
        //         <p>Drafted the full standards that define the output of video analytics algorithms for
        //             government video analytics systems</p>
        //     </div> */}
    )
}
export default Portfolio