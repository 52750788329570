import React from 'react';
import {Link} from "react-router-dom";

function Navitem(props) {
    return (
        <li id={props.item}>
            <Link to={props.tolink} style={{textDecoration: 'none', color:'black'}}>{props.item}</Link>
        </li>
    )   
}

export default Navitem;