import React  from 'react';

function About() {
    return (
        <div className="condiv">
            {/* <h1 className="subtopic">About Me</h1> */}
            <h2>Greetings! My name is </h2>
            <h1 className="name_header">Christopher Sia</h1>
            <br></br>
            <h2>I am an <u>AI Engineering Manager</u> developing <u>AI products</u></h2>
            <br></br>
            <p>
                My 15+ years of experience in the tech industry spans across software engineering, quality assurance,
                strategic planning, business development, project management and product management.
                Coding is a big part of my life. I developed software applications, implemented algorithms,
                trained and deployed AI models for computer vision. 
                <br></br>
                <br></br>
                I enjoy solving problems, both technical and business. I like to develop end to end solutions
                that brings value to people. I like to develop ideas. Not just that, I always see that such ideas and
                concepts can be implemented.
                <br></br>
                <br></br>
                I respect rules but choose not to be conformed by it. I think of possibilities first before constraints.
                I think of partnerships before competitions. Basically, I just like to do things that can "change the world
                for the better"
            </p>
        </div>   
    )
}
    
export default About
    